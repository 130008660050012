import React, { Component } from 'react';
import styles from './FileuploadButton.module.css';

class FileuploadButton extends Component {
    hiddenUploadBtn = React.createRef();

    getAcceptedFileTypes = () => {
        let { accept } = this.props;
        if (typeof accept === 'string') {
            accept = accept.split();
        }
        return accept.map(a => `.${a}`).join();
    };

    onFileChanged = e => {
        if (this.props.multiple) {
            this.props.onChange([...e.target.files]);
        } else {
            this.props.onChange(e.target.files[0]);
        }

        const fileElement = this.hiddenUploadBtn.current;
        if (fileElement && this.props.clearFileAfterSelection) {
            fileElement.value = '';
        }
    };

    renderHiddenUploadField = () => {
        return (
            <input
                type='file'
                className={styles.hiddenUploadBtn}
                multiple={this.props.multiple}
                ref={this.hiddenUploadBtn}
                onChange={this.onFileChanged}
                accept={this.getAcceptedFileTypes()}
            />
        );
    };

    clickUpload = () => {
        if (this.props.disabled) {
            return;
        }

        this.hiddenUploadBtn.current.click();
    };

    render () {
        return <>
            {this.renderHiddenUploadField()}
            <span onClick={this.clickUpload}>
                {this.props.trigger}
            </span>
        </>;
    }
}

FileuploadButton.defaultProps = {
    clearFileAfterSelection: false,
    size: 'medium',
    icon: 'cloud upload',
    content: 'Upload',
    accept: [],
};

export default FileuploadButton;